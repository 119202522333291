<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <input type="text" v-model="item.modalText" placeholder="Text in modal" />

      <div class="ml-40">
        <input
          type="text"
          v-model="item.lettersToShow"
          placeholder="Ex: a / b / c"
        />

        <input type="text" v-model="item.correctAnswer" placeholder="Answer" />
      </div>
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
// {
//   "modalText": "Найди пропущеную букву в слове aer_plane",
//   "lettersToShow": [
//     "e",
//     "o",
//     "l"
//   ],
//   "correctAnswer": "o"
// },
//   ]
export default {
  name: 'Type5c',
  data() {
    return {
      letterWords: [
        {
          modalText: '',
          lettersToShow: '',
          correctAnswer: '',
        },
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords)).map((lw) => {
        lw.lettersToShow = lw.lettersToShow
          .replace(/\s/g, '')
          .replace(/[\,\/]/gi, ',')
          .split(',');
        return lw;
      });
      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push({
          modalText: '',
          lettersToShow: '',
          correctAnswer: '',
        });
    },
  },
};
</script>
